<template>
  <div class="signup-success">
    <h1 class="signup-success__title">Account Created</h1>
    <div class="signup-success__text text">
      We have successfully created your new account.
    </div>
    <ButtonBase class="signup-success__btn" @click="goToAuth">
      Sign in
    </ButtonBase>
  </div>
</template>

<script>
export default {
  methods: {
    goToAuth() {
      this.$router.push({ name: 'auth' });
    },
  },
};
</script>

<style lang="sass" scoped>
.signup-success
  display: flex
  flex-direction: column
  align-items: center
  text-align: center

.signup-success__text
  @include adaptive-font-size(16, 14, 12)
  @include adaptive-line-height(24, 24, 18)
  margin-top: 12px
  margin-bottom: 64px
  @include ms
    margin-top: 8px
    margin-bottom: 40px

.signup-success__btn
  padding: 12px 76px
</style>
